<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addRole']">新增角色权限</a-button>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editRole']">
          <img src="@/assets/image/common/link.png" alt="" class="margin-l20" @click="openTree(record)" v-has-permission="['setRoleAuth']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteRole']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}角色权限` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="角色名称" prop="roleName">
              <a-input placeholder="请输入角色名称" v-model="form.roleName" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-modal
        v-model="visible"
        title="设置角色权限"
        centered
        :width="800"
        @ok="setRole"
        :confirmLoading="setRoleLoading"
        :bodyStyle="{ 'height' : '60vh', 'overflow-y' : 'auto' }"
    >
      <a-tree
          :replaceFields="replaceFields"
          :tree-data="roleTree"
          :defaultExpandAll="true"
          v-model="selectedKeys"
          :check-strictly="true"
          checkable
          @check="treeCheck"
          v-if="roleTree && roleTree.length"
      >
      </a-tree>
    </a-modal>
    <div class="loading" v-if="treeLoading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 32px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getRoleList, createRole, deleteRole, getRoleTree, setRoleAuths } from '../../api/system/role'
import { mapState } from 'vuex'

export default {
  name: 'role',
  data () {
    return {
      columns: [
        {
          title: '角色名称',
          dataIndex: 'roleName'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      },
      roleTree: [],
      visible: false,
      replaceFields: {
        title: 'authName',
        key: 'authCode',
        value: 'authCode'
      },
      selectedKeys: [],
      selectedId: '',
      treeLoading: false,
      setRoleLoading: false
    }
  },
  mounted () {
    this.getList ()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getRoleList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = { ...item }
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteRole({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createRole(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.createShow = false
          })
        }
      })
    },
    openTree (item) {
      this.selectedKeys = []
      this.treeLoading = true
      this.selectedId = item.id
      getRoleTree({
        roleId: item.id
      }).then(res => {
        this.roleTree = res.data
        this.filterTree(this.roleTree)
        this.visible = true
        this.treeLoading = false
      })
    },
    filterTree (item) {
      let arr = []
      const treeToList = (tree) => {
        if (tree && tree.length) {
          tree.forEach(v => {
            if (v.selected) arr.push(v.authCode)
            if (v.children && v.children.length) {
              treeToList(v.children)
            }
          })
        }
      }
      treeToList(item)
      this.selectedKeys = arr
      this.$forceUpdate()
    },
    treeCheck(checkedKeys, info) {
      this.mapCheckTree(checkedKeys, this.roleTree)
      console.log(this.roleTree)
    },
    mapCheckTree (keys, data) {
      data.forEach(item => {
        if (keys.checked.indexOf(item.authCode) > -1) {
          item.selected = true
        } else {
          item.selected = false
        }
        if (item.children && item.children.length) {
          this.mapCheckTree(keys, item.children)
        }
      })
    },
    setRole () {
      this.setRoleLoading = true
      setRoleAuths({
        roleId: this.selectedId,
        roleAuths: this.roleTree
      }).then(() => {
        this.$message.success('设置成功！')
        this.setRoleLoading = false
        this.visible = false
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
  }
</style>
